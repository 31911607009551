<template>
  <h1>Kinesiologie</h1>
  <p>
    Der Begriff Kinesiologie stammt aus dem Griechischen und heißt übersetzt,
    die „Lehre von der Bewegung“.
  </p>
  <p>
    Die Kinesiologie hat sich in den sechziger Jahren aus der Chiropraktik
    entwickelt. Der Amerikaner Prof. Dr. George Goodhart beobachtete, dass sich
    physische und psychische Vorgänge im Menschen auch im Funktionszustand
    seiner Muskeln spiegeln. Zudem befasste er sich mit dem Energiemodell der
    chinesischen Elemente-, Meridian- und Akupunkturlehre und erkannte, dass die
    Meridiane, nicht nur Einfluss auf die Organe nehmen, sondern auch mit ganz
    bestimmten Muskeln in Verbindung stehen.
  </p>
  <p>
    Aus diesen Erkenntnissen entwickelte er Muskeltestverfahren, über deren
    Reaktionen es möglich war, Blockaden, Einflüsse oder Stressoren, denen ein
    Mensch ausgesetzt sein kann, zu identifizieren. Gleichzeitig nutzte er den
    Muskeltest als körpereigenes Feedbacksystem, mit dem er testete, was, wann
    in welcher Reihenfolge zur Unterstützung und Korrektur erfolgen sollte.
  </p>
  <p>
    Aus der Angewandten Kinesiologie entwickelten sich im Laufe der Jahre
    unterschiedliche Methoden, die in der Medizin und in der Heilkunde, in der
    Psychotherapie, in der Pädagogik, im Coaching, im Sport, in der Musik und
    ganz besonders in der Selbsthilfe Anwendung finden.
  </p>
  <h2>
    Die chinesische Energielehre, ein Aspekt der kinesiologischen Denkweise
  </h2>
  <p>
    In der „ursprünglichen chinesischen Philosophie“ ist der Mensch„ Teil eines
    Ganzen“ und folgt über den „Gesetzmäßigkeiten der Fünf Elemente“, der
    „Universellen Schöpfung“. Der menschliche Organismus spiegelt sich mit
    seinen ganzheitlichen Funktionen in der „Ordnung der Natur“ - und der
    einzelne Mensch wird als „Mikrokosmos“ im „Makrokosmos“ betrachtet. Die
    körperlichen, emotionalen, geistigen, seelischen und spirituellen Aspekte
    sind den Fünf Elementen, die auch als „Wandlungsphasen“ bezeichnet werden,
    zugeordnet worden.
  </p>
  <p>
    In diesem Denkmodel unterliegt der Mensch als ein Teil der Wandlungsphasen
    vielen Einflüssen, z. B. denen der Naturgesetzte (z.B. Jahreszeiten und
    Klima), denen der Zeitepochen und Kultur und deren Normen und Werte sowie
    denen der Sozialisation und Erziehung, aber auch den kosmischen und
    universellen. Jedem Element sind die sogenannten Meridiane zugeordnet und
    diese wiederum den Organen.
  </p>
  <p>
    Nach Auffassung der Energielehre, fließt in diesen, sich durch den ganzen
    Körper ziehenden unsichtbaren Bahnen, die universelle Lebensenergie, das
    „Qi“ (Schi). Es wird davon ausgegangen, dass das Qi die „Körperfunktionen“
    aufbaut und erhält.
  </p>
  <div class="img">
    <img
      src="../assets/kinesiologie/Qi.png"
      alt="Darstellung der 5 Elemente des Qi"
    />
  </div>
  <p>
    Zudem trägt jeder Meridian, geistige, emotionale, psychische und spirituelle
    Aspekte in sich, die sich im jeweiligen Element in verschieden Qualitäten,
    z.B. als Stärken, Tugenden, und Charaktereigenschaften wiederfinden. Es wird
    angenommen, dass der Fluss der Lebensenergie Qi, durch von außen einwirkende
    „negative Stressoren“ blockiert und geschwächt werden kann, was sich
    ungünstig bis krankmachend auf das ganzheitliche Zusammenspiel im Menschen
    auswirken kann.
  </p>
  <p>
    In der Tradition des chinesischen Energiemodels wird durch verschiedene
    Verfahren, z. B. Akupunktur, Akupressur, Ernährung, Bewegungsformen (Yoga,
    Tai Chi, usw.) versucht, die Gesundheit zu erhalten, bzw. Blockaden zu
    lösen, damit die Energien wieder frei fließen können. In Anlehnung an diese
    Betrachtung, glaubt die Kinesiologie daran, dass die Energien in einem
    Menschen ausgleichbar - „balancierbar“ sind, dass sich Blockaden lösen
    können und sich dadurch selbstregulierend, zwischen Körper, Geist, Emotionen
    und Seele ein Gleichgewicht (wieder) herstellen kann.
  </p>
  <p>
    Die Annahme ist, dass einem Menschen der im Gleichgewicht, in der Balance zu
    sich selbst - in seiner Mitte ist - ganzheitliche Lebenskraft/Lebensenergie
    zur Verfügung steht, die ihm Lern- und Leistungsfähigkeit, mentale und
    emotionale Ausgeglichenheit so wie geistige und körperliche Gesundheit
    ermöglicht. Der unterstützende Prozess, in dem verschiedene kinesiologische
    Methoden und deren Techniken angewendet werden können, wird „Balance“ oder
    „Balancearbeit“ genannt.
  </p>
  <p>
    Die Art der kinesiologischen Anwendung definiert sich durch den beruflichen
    Hintergrund des Anwenders. Als Erzieherin arbeite ich in der Begleitenden
    Kinesiologie.
  </p>

  <h2>
    Ziele in der Balancearbeit als Basis für Lern- und Veränderungsprozesse
  </h2>
  <p>
    Der Klient formuliert seine Ziele und bestimmt damit selbst, wohin sich der
    persönliche Lern- und Veränderungsprozess bewegen soll. Mit Hilfe der
    kinesiologischen Methoden wird der Klient darin unterstützt, seine Ziele
    eigenständig, selbstbestimmt, und selbstbewusst für sein persönliches
    Wachstum zu erreichen.
  </p>
  <p>
    Die Ziele können sich auf alle Lebensbereiche beziehen in denen ein Klient
    (positive) Veränderungen wünscht. Sie können das schulische/akademische
    Lernen, die individuelle, berufliche Selbstverwirklichung, die Gestaltung
    des Lebens- und Beziehungsalltags, gesundheitliche Selbstfürsorge, aber auch
    sportliche, kreative, künstlerische und musische Freizeitaktivitäten
    betreffen.
  </p>

  <h2>Begleitende Kinesiologie - Lernen als lebenslanger Prozess</h2>
  <p>
    Die Begleitende Kiensiologie hat „Ganzheitliches Lernen“ in den Mittelpunkt
    gestellt. Lernen wird als Prozess betrachtet, der dem Klient gemäß seiner
    Zielsetzungen ermöglicht, Lösungen zu finden, die ihm hilfreich erscheinen,
    seine persönlichen Herausforderungen zu verändern. Die Begleitende
    Kinesiologie sieht „ganzheitliche Bewegungsfähigkeit“ als Basis für
    Veränderungsprozesse und hat sich darauf ausgerichtet, diese zu fördern und
    zu unterstützen. Nach Auffassung der Begleitenden Kinesiologie gelingt
    Lernen dann besonders gut, wenn Körper, Geist, Emotionen und Seele in
    Kommunikation und Einklang miteinander sind.
  </p>

  <h2>Der Muskeltest in der Begleitenden Kinesiologie</h2>
  <p>
    In der Begleitenden Kiensiologie wird der Muskeltest in einer Balance als
    eine „Gesprächsform“ des Körpers betrachtet.
  </p>
  <div class="img">
    <img src="../assets/kinesiologie/muskelkommunikation.png" alt="" />
  </div>
  <p>
    Über diese „Muskelkommunikation“ kann zu dem vom Klienten formulierten Ziel
    sondiert und anschließend mit verschiedenen Methoden und Techniken
    balanciert werden. Der Musekeltest ersetzt keines Falls das
    eigenverantwortlich Denken und Handeln des Klienten.
  </p>

  <h2>Von der Blockade in die Bewegung</h2>
  <p>
    Die Kinesiolgie glaubt daran, dass das „Wissen“, was für die
    „Veränderungsprozesse“ benötigt wird, bereits im Menschen vorhanden ist, ihm
    aber auf Grund von „Stressblockaden“, im Augenblick der geistigen, mentalen,
    emotionalen oder körperlichen Problemsituation, nicht zur Verfügung stehen.
  </p>

  <h2>
    Begleitende Kinesiologie kann Bewegung, Veränderung und Balance in jeder
    Ebene möglich machen
  </h2>

  <h3>körperlich</h3>
  <p>
    Wenn sich Spannungen und Blockaden lösen, kann ein Gleichgewicht hergestellt
    werden zwischen:
  </p>
  <ul>
    <li>Bewegungsfähigkeit und Haltung</li>
    <li>Grob- und Feinmotorik</li>
    <li>Bewegung, Sinnesverarbeitung und Konzentration</li>
    <li>Nahrungsaufnahme und Stoffwechsel</li>
  </ul>

  <h3>geistig/mental/kognitiv</h3>
  <p>
    Wenn Denkblockaden, alte Gedanken- und Glaubensmuster losgelassen werden,
    kann ein Gleichgewicht hergestellt werden zwischen:
  </p>

  <ul>
    <li>alten und neuen Verhaltensreaktionen</li>
    <li>Aufnahme- und Merkfähigkeit</li>
    <li>Kreativität und Ausdruck</li>
    <li>Ziele erkennen, durchhalten und Ziele erreichen</li>
  </ul>

  <h3>seelisch/emotional</h3>
  <p>
    Wenn sich stressbesetztes, emotionales Erleben verändert, kann ein
    Gleichgewicht hergestellt werden zwischen:
  </p>
  <ul>
    <li>
      der Wahrnehmung von Belastungen und Probleme und der eigenen Fähigkeit
    </li>
    <li>diese zu lösen, bzw. positiver mit ihnen umgehen zu können</li>
    <li>Selbst- und Fremdwahrnehmung</li>
    <li>Eigen- und Nächstenliebe</li>
  </ul>

  <h2>Kinesologische Methoden mit denen ich in der Praxis arbeite</h2>
  <h3>Touch for Health (TFH)</h3>
  <p>
    „Gesund durch Berühren“ wurde von John F. Thie aus der Angewandten
    Kinesiologie mit der Absicht entwickelt, dieses komplexe Verfahren für jeden
    zur Selbsthilfe und zur Gesundheitsvorsorge zugänglich zu machen. TFH
    enthält Elemente aus der Chiropraktik, Akupressur und
    Ernährungswissenschaft.
  </p>
  <p>
    Durch verschiedene Muskeltests lassen sich Ungleichgewichte in den
    Energiesystemen (Meridianen) erkennen und durch unterschiedliche Verfahren
    direkt ausgleichen. Hier kommen besonders - daher der Name der Methode -
    verschiedene Berührungstechniken wie z. B. sanftes halten, massieren oder
    klopfen von Akupressur- und Reflexpunkten, zum Einsatz.
  </p>
  <p>
    In der Sitzung lernt der Klient diese Verfahren kennen und wie er diese im
    Lebensalltag zur Selbsthilfe weiter anwenden kann. Mit TFH lassen sich
    Herausforderungen im Bewegungsapparat, die Nahrungsaufnahme und der
    Stoffwechsel, aber auch emotionale und stressbesetzte Themen ausbalancieren.
  </p>

  <h3>Edu-Kinestetik (EDU-K)</h3>
  <p>
    Dieser Begriff umfasst eine Reihe von Methoden die von Dr. Paul Dennison und
    seiner Frau Gail entwickelt wurden. Sie basieren auf dem Wissen und
    Erfahrungen der Angewandten Kinesiologie, der Gehirnforschung, der
    Lernpsychologie, und der chinesischen Energielehre.
  </p>
  <p>
    Brain Gym®, das ist die bekannteste Methode und umfasst sechsundzwanzig
    Körper- und Energieübungen die besonders die Lernfähigkeit unterstützen.
    Brain Gym® fördert die Zusammenarbeit der Gehirnbereiche untereinander,
    sowie deren Aufgaben, Bewegung und Sinnesverarbeitung zu koordinieren.
  </p>
  <p>
    Lesen, Schreiben, Rechnen, Konzentration, Kreativität und Ausdruck,
    emotionale und soziale Kompetenz, aber auch Motivation, Neugierde, Freude
    und Durchhaltevermögen werden unterstützt. Im Lebensalltag können die
    Übungen helfen, jegliche Art von Anforderungen und Herausforderungen
    leichter zu meistern.
  </p>

  <h3>Three in One Concepts</h3>
  <p>
    Gordon Stokes, Daniel Whiteside, Candice Callaway entwickelten gemeinsam das
    „Drei in einem Konzept“. Der Name der Methode beschreibt die Absicht, 1.
    körperliche, 2. geistige und 3. seelische Fähigkeiten zu integrieren. Alte
    stressbesetzte Lernerfahrungen hindern daran, klar in der Wahrnehmung und
    offen für Neues zu sein. Mit Hilfe des, in dieser Methode entwickelten
    „Verhaltensbarometers“, werden emotionale Blockaden und Reaktionen
    identifiziert und balanciert.
  </p>

  <h3>Advanced Energy Psychology&copy; (AEP)</h3>
  <p>
    “Fortgeschrittene Energetische Psychologie“ wurde von Fred P. Gallo
    entwickelt und bietet die Möglichkeit, psychische Befindlichkeiten und
    Verhaltensweisen über die emotionalen Aspekte der Meridianlehre zu
    betrachten. Über das Klopfen spezifischer Akupressurpunkte können sich
    unerwünschte und belastende Befindlichkeiten (Schuldgefühle, Eifersucht,
    Wertlosigkeit usw.) in der subjektiven Wahrnehmung positiv verändern oder
    ganz auflösen. Bei unangebrachten Angewohnheiten, überschießenden Reaktionen
    oder Vermeidungsverhalten kann das AEP hilfreich sein.
  </p>

  <h2>Hinweise zur Begleitenden Kinesiologie</h2>
  <div class="information">
    <p>
      Nach Meinung einer Vielzahl von Wissenschaftlern, ganzheitlich
      orientierten Ärzten, Psychotherapeuten und Heilpraktikern kann die
      Kinesiologie begleitend und ergänzend zur Unterstützung einer
      medizinischen, psychotherapeutischen oder heilpraktischen Behandlung
      beitragen. In der Schulmedizin wird sie auf Grund von nicht gesicherten,
      wissenschaftlichen Erkenntnissen, bezüglich ihrer Wirksamkeit, nicht
      anerkannt.
    </p>
    <p>
      Die Begleitende Kinesiologie stellt keine Heilkunde dar. Sie ist kein
      Ersatz für medizinische und psychotherapeutische Behandlungen und dient
      nicht der Heilung von Krankheiten. Die Begleitende Kinesiologie versteht
      sich als Lebensberatung, die „Gesundheitsfürsorge“ und „ganzheitliches
      Lernen als lebenslangen Prozess“ in den Mittelpunkt gestellt hat. Bei
      gesundheitlichen Beschwerden und Krankheiten sollte eine medizinische und/
      oder psychotherapeutische Behandlung in Anspruch genommen - und der
      behandelnde Arzt u./o. Psychotherapeut über die Begleitende
      Kinesiologische Gesundheits- und Lebensberatung - informiert werden.
    </p>
  </div>
</template>

<script>
export default {
  name: "Kinesiologie",
};
</script>

<style></style>
